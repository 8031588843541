import React from 'react';
import ExampleWithLightbox from './components/ExampleWithLightbox';
import { Auth } from 'aws-amplify';
import  './amplify';



class App extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      authenticated: false,
      authentication_status_resolved: false,
      authentication_error: "Password",
      password: '',
    }

    this.onChange = this.onChange.bind(this);
    this.signIn = this.signIn.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.resetAuthenticationError = this.resetAuthenticationError.bind(this);
  }

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log('user:', user)
      if (user) {
        this.setState({ authenticated: true });
      } else {
        this.setState({ authenticated: false });
      }
      this.setState({ authentication_status_resolved: true });
    } catch (err) {
      console.log({ err });
      this.setState({ authenticated: false });
      this.setState({ authentication_status_resolved: true });
    } 
  }

  /* onChange handler for form inputs */
  onChange(e) {
    this.setState({ password: e.target.value });
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.signIn()
    }
  }

  resetAuthenticationError() {
    this.setState({ authentication_error: "Password" });
  }

  /* Sign in function */
  async signIn() {
    this.setState({ authentication_status_resolved: false });
    try {
      await Auth.signIn('weddingAttendee', this.state.password).then(() => {
        this.setState({ authenticated: true });
        this.setState({ authentication_status_resolved: true });
      }).catch((reason) => {
        console.error(reason);
        this.setState({ authentication_error: "Wrong password" });
        this.setState({ authenticated: false });
        this.setState({ authentication_status_resolved: true });
      });
      
    } catch (err) {
      console.log({ err });
      this.setState({ authentication_error: "Error, try again" });
      this.setState({ authenticated: false });
      this.setState({ authentication_status_resolved: true });
    }
  }

  render() {
    if (this.state.authentication_error !== "Password") {
      setTimeout(this.resetAuthenticationError, 5000);
    }
    if (!this.state.authentication_status_resolved) {
      return (
        <div className="App">
          Loading...
        </div>
      )
    } else if (this.state.authenticated) {
      return (
        <div className="App">
          <ExampleWithLightbox />
        </div>
      );
    } else {
      return (
        <div className="App">
          <input
            className="garamond password"
            name="password"
            placeholder={this.state.authentication_error}
            onChange={this.onChange}
            onKeyDown={this.handleKeyDown}
          />
          <br />
          <button
            className="garamond signin"
            onClick={this.signIn}>Sign In</button>
        </div>
      )
    }
  }
}
export default App;
