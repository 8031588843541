import React, { useState, useCallback, useEffect } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Storage } from 'aws-amplify';

/*
function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}
*/

async function loadPhotos(callback) {
  let manifest = await Storage.get('manifest.json', { level: 'private', download: true });
  manifest.Body.text().then(string => { 
    return JSON.parse(string);
  }).then(async (jsonData) => {
    Promise.all(
      Object.values(jsonData).map(async item => {
        for (const size in item) {
          item[size].path = `/photos/${item[size].path}`;
        }
        return {
          src: item['1200'].path,
          width: parseInt(item['1200'].width),
          height: parseInt(item['1200'].height),
          title: `<a class="download-button" href="${item['original'].path}" download>Download</a>`,
          key: item['1200'].path,
          srcSet: [
            `${item['300'].path} ${item['300'].width}w`,
            `${item['600'].path} ${item['600'].width}w`,
            `${item['1200'].path} ${item['1200'].width}w`
          ],
          sizes: '(min-width: 480px) 300px, 600px, (min-width: 1024px) 1200px',
        };
      })
    ).then((images) => {
      //images = shuffle(images);
      callback(images);
    })
  })
  .catch((error) => {
    // handle your errors here
    console.error(error)
  })
}

function ExampleWithLightbox() {
  const [photos, setPhotos] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setViewerIsOpen(false);
  };

  useEffect(() => {
    loadPhotos(setPhotos);
  }, []);
  
  if (photos) {
    return (
      <div>
        <Gallery photos={photos} onClick={openLightbox} direction={"row"}/>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  } else {
    return (
      <div className="loading-msg" id="msg-loading-more">Loading</div>
    )
  }
}

export default ExampleWithLightbox;
