import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
    Auth: {
        identityPoolId: 'eu-west-2:6bd4c6e7-a6df-47b9-a99e-d44840c062b2',
        region: 'eu-west-2',
        userPoolId: 'eu-west-2_sEsKFuc43',
        userPoolWebClientId: '38ra4i4k5de9iinlr544m2m4nc',
        mandatorySignIn: true,
        authenticationFlowType: 'USER_SRP_AUTH',
        cookieStorage: {
            domain: 'wedding.ercolemoroni.com',
            path: '/',
            expires: 365,
            sameSite: "strict",
            secure: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN !== "localhost",
        },
    },
    Storage: {
        AWSS3: {
            bucket: 'ercolemoroni-wedding-photos',
            region: 'eu-west-2',
        }
    }
});

export const currentConfig = Auth.configure();
